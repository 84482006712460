exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuActiveText": "#0C8AFF",
	"menuColor": "#fff",
	"menuLightColor": "#fff",
	"menuColorActive": "#f4f4f5",
	"menuBackground": "#222",
	"menuLightBackground": "#2B68C9",
	"subMenuBackground": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "200px",
	"logoTitleColor": "#fff",
	"logoLightTitleColor": "#001529"
};