import Layout from '@/layout';
var distributionRouter = {
  path: '/distribution',
  component: Layout,
  redirect: '/distribution/index',
  name: 'Distribution',
  meta: {
    title: '分销',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/distribution/index');
    },
    name: 'distributionIndex',
    meta: {
      title: '分销员管理',
      icon: ''
    }
  }, {
    path: 'distributionconfig',
    component: function component() {
      return import('@/views/distribution/config/index');
    },
    name: 'distributionConfig',
    meta: {
      title: '分销配置',
      icon: ''
    }
  }, {
    path: 'share',
    component: function component() {
      return import('@/views/distribution/share/index');
    },
    name: 'distributionShare',
    meta: {
      title: '分销员推单',
      icon: ''
    }
  }]
};
export default distributionRouter;