//
//
//
//
//
//

export default {
  name: 'App',
  created: function created() {
    console.log(process.env, 1111);
  }
};